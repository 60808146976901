<script>
  import {mapState, mapMutations} from 'vuex';
  import PostPage from '@/pages/BuyerPages/assignment_pages/b_PostSecretaryInterpretation';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/preselectInterpretationEditStaticData')];

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    extends: PostPage,
    computed: {
      ...mapState('PostInterpretationStore', {
        shouldBeDisabledImportantFields: ({disabledImportantFields}) => disabledImportantFields
      }),
      mainLinesTitle() {
        return this.$gettext('Secretary assignment');
      },
      submitBtnText() {
        return this.$gettext('Edit');
      },
      reviewModalBtnText() {
        return this.$gettext('Edit assignment');
      },
      disabledImportantFields() {
        return this.shouldBeDisabledImportantFields && !this.$isGodmode();
      }
    },
    methods: {
      ...mapMutations('PostInterpretationStore', [
        'setJobId'
      ]),
      sendInfoFunc() {
        this.$store.commit('PostInterpretationStore/setTrackType', 'job_updated');
        return this.$store.dispatch('PostSecretaryStore/editJob');
      },
      getJobToEdit() {
        this.postAssignmentProgress = true;
        this.$store.dispatch('PostInterpretationStore/getJob', this.$route.params.id).then(() => {
          this.postAssignmentProgress = false;
        }).catch(() => {
          this.postAssignmentProgress = false;
        });
      }
    },
    mounted() {
      this.setJobId(this.$route.params.id);
      if (!this.$store.state.existingEditJobInfo) {
        this.getJobToEdit();
      }
    }
  };
</script>
